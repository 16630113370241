.font-en{
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 1px;
}
.h2C{
    font-weight: 700;
}
.button-more{
    font-family: 'Quicksand', sans-serif;
    letter-spacing: 1px;
}
.hdrTop .hdrInfo{
    text-align: center;
}
.hdrMiddle.hdrMiddle--logoCenter .hdrInner .hdrMiddle__left{
    display: flex;
    column-gap: 80px;
    flex: 0 0 40%;
    max-width: 40%;
    .gNav > ul {
        & > li > a{
            flex-direction: column;
            justify-content: center;
            gap: 10px 0;
            &:hover{
                font-weight: 700;
            }
        }
    }
}
.hdrMiddle.hdrMiddle--logoCenter .hdrInner .hdrMiddle__center{
    flex: 0 0 20%;
}
.hdrMiddle.hdrMiddle--logoCenter .hdrInner .hdrMiddle__right{
    flex: 0 0 40%;
    max-width: 40%;
    .iconNav__cart .cartAmount{
        top:0px;
        right:5px;
    }
}
.searchForm.searchFormB .searchForm__inner{
    border-color:#68B5B7;
}
#drawer{
    .drawer_banner{
        padding: 15px 15px 0;
        img{
            max-width: 100%;
        }
    }
    .drawer__search{
        border-color:#68B5B7;
    }
    .drawer__nav{
        li a{
            border-color:#68B5B7;
        }
        .drawer__itemList{
            border-bottom: 1px solid #68B5B7;
            .itemList__unit{
                padding-left: 5px;
                padding-right: 5px;
            }
        }
        .hasChildren--click{
            ul > li > a{
                background:#F3F9F9;
                border: none;
                &::after{
                    content: none;
                }
            }
            ul > li:last-child > a{
                border-bottom: 1px solid #68B5B7;
            }
        }
    }
    .drawer__accountText{
        border-color:#68B5B7;
    }
    .drawer__accountNav li{
        a{
            border-color:#68B5B7;
            &:nth-child(odd){
                border-right:none;
            }
        }
        &:nth-child(odd) a{
            border-right: 1px solid #68B5B7;
        }
    }
}
.sec-worry{ 
    padding-bottom: 80px;
    background: linear-gradient(180deg, #fff 0%, #fff 40%, #F3F9F9 40%, #F3F9F9 100%);
    @media (max-width: 767px){
        padding-bottom: 40px;
        background: linear-gradient(180deg, #fff 0%, #fff 25%, #F3F9F9 25%, #F3F9F9 100%);
    }
    .itemArrange__img{
        margin-bottom: 15px;
        img{
            margin:0 auto;
        }
    }
    .row .h2C{
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 10px;
        .worry__text{
            margin-bottom: 30px;
        }
    }
    .button{
        max-width: 100%;
    }
}
.sec-search {
    .row{
        justify-content: center;
        a.button.arrow{
            text-align: left;
            max-width: 100%;
            &:hover{
                background-color: #F3F9F9;
            }
            &::after{
                border-width: 2px;
            }
            @media (max-width: 767px){
                font-size: 12px;
                padding-left:15px;
            }
        }
    }
}

.tab{
    .tabSwitch{
        flex-wrap: wrap;
        li{
            border:none;
            font-weight: 400;
        }
        li.active{
            border:none;
            background-color: #F3F9F9 ;
            font-weight: 700;
        }
        li:hover{
            border:none;
            background-color: #F3F9F9 ;
            font-weight: 700;
        }
        li:after{
            content:none;
        }
    }
}
.sec-ranking{
    .itemList--ranking{
        .itemList__unit{
            .itemWrap::before{
                border:none;
                color:#fff;
                background:#68B5B7;
                font-family: 'Quicksand', sans-serif;
            }
            &:nth-child(1){
                .itemWrap::before{
                    background: #B6A053;
                }            
            }
            &:nth-child(2){
                .itemWrap::before{
                    background: #B1B1B1;
                }            
            }
            &:nth-child(3){
                .itemWrap::before{
                    background: #967454;
                }            
            }
        }
    }
}
@media (max-width: 767px){
    .sec-ranking .tab .tabSwitch > li {
        width: 50%;
    }
}
.sec-faq{
    .hasChildren--click{
        line-height:24px;
        margin-bottom:20px;
        font-size: 16px;
        border-bottom: 1px solid #F3F9F9;
        border-top: 1px solid #F3F9F9;
        .cateList__children{
            display:none;
            margin-top:0;
            padding:0 20px 20px;
        }
        a.q_text{
            position:relative;
            display:block;
            width:100%;
            padding:20px;
            font-weight:700;
            &::before{
                content:"Q.";
                margin-right:5px;
                color:#68B5B7;
                font-weight:700;
                font-size:24px;
                font-family: 'Quicksand', sans-serif;
                letter-spacing: 1px;     
                @media (max-width: 767px){
                    font-size: 20px;
                }       
            }
            &::after{
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                width: 9px;
                height: 9px;
                border-top: 2px solid #68B5B7;
                border-right: 2px solid #68B5B7;
                transform: rotate(135deg) translateY(-50%);
                transform-origin: top center;
                right:15px;
                transition: 0.15s;
            }
            &.open::after{
                transform: rotate(-45deg) translateY(-50%);
            }
        }
        .a_text{
            display:flex;
            padding-top:20px;
            border-top: 2px solid #F3F9F9; 
            font-weight:500;
            &::before{
                content:"A.";
                margin-right:5px;
                color:#E05A73;
                font-weight:700;
                font-size:24px;
                font-family: 'Lato', sans-serif;
                letter-spacing: 1px;        
                @media (max-width: 767px){
                    font-size: 20px;
                }    
            }
        }
    }
    @media (max-width: 767px){
        .hasChildren--click .cateList__children{padding:0 15px 15px;}
        .hasChildren--click a.q_text{padding:15px;font-size:12px;}
        .hasChildren--click a.q_text::after{right:5px;}
    }
}

.review-list{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap:20px;
    flex-direction: column;
    li{
        padding: 22px 30px;
        display:flex;
        align-items: center;
        .review-star{
            color:#FFD60E;
            margin-bottom:10px;
          }
        .review-list__textwrap{
            padding: 20px 0 20px 20px;
            img{
                margin-bottom: 15px;
            }
            .review-list__title{
                font-weight: 700;
                margin-bottom: 10px;
                line-height: 24px;
            }
        }
        @media (max-width: 767px){
            flex-direction: column;
            padding: 10px;
            .review-list__textwrap{
                padding: 10px;
            }
        }    
    }
}
.fNav--typeA .fNav__list h3{
    font-weight: 700;
}
@media (min-width: 992px){
    .fNav--typeA{
        padding: 40px 0 80px;
    }
    .fNav--typeA .fNav__logo {
        margin-right: 45px;
    }
    .sec-special{
        .h2A{
            font-weight: 400;
            margin:47px 0 20px 0;
        }
    }
}
