$color_body_bg:#FFF; //サイト背景
$color_text:#68B5B7; //サイト文字色

$color_highlight_bg: #F3F9F9; //ハイライト
$color_highlight_text: $color_text; //ハイライト文字色

$color_primary:#68B5B7; //メインカラー
$color_primary_text:#ffffff; //メインカラー文字色

$color_secondary:#3d4744; //サブカラー
$color_secondary_text:#ffffff; //サブカラー文字色

$color_cta: $color_primary; //メインカラー
$color_cta_text:#ffffff; //メインカラー文字色

$color_border:#dfdfdf; //ボーダーカラー

$color_white: #FFF;
$color_black: #1b1b1b;

$color_text_link:$color_text;


// ボタン
$color_button_text: #FFF;
$color_button_text_hover: $color_button_text;
$color_button_bg: $color_primary;
$color_button_bg_hover: $color_primary;


// CSS変数
:root {
    --primary-color: #{$color_primary};
    --primary-text: #{$color_primary};
    --secondary-color: #{$color_secondary};
    --secondary-text: #{$color_secondary};
    --cta-color: #{$color_cta};
    --cta-text: #{$color_cta_text};
}